import axios from 'axios';
import React, { useState } from "react";
import Layout from "../components/layout";
import contactStyles from "./contact.module.scss"
import { graphql, useStaticQuery } from "gatsby";

const GETFORM_FORM_ENDPOINT = "https://getform.io/f/02ad1c25-91a3-4da9-aa79-165c2d37c01e";

const ContactPage = () => {

    const [formStatus, setFormStatus] = useState(false);
    const [query, setQuery] = useState({
        name: "",
        email: "",
        subject: "",
        message: "",
    });

    const handleChange = () => (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setQuery((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        Object.entries(query).forEach(([key, value]) => {
            formData.append(key, value);
        });

        axios
            .post(
                GETFORM_FORM_ENDPOINT,
                formData,
                {headers: {Accept: "application/json"}}
            )
            .then(function (response) {
                setFormStatus(true);
                setQuery({
                    name: "",
                    email: "",
                });
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const data = useStaticQuery (graphql`
    query {
        contentfulContact{
            email
            telephone
            address
            image {
                file {
                    url
                    fileName
                }
            }
        }
    }
    `)

    const info = data.contentfulContact

    return (
        <Layout>
            <div className={contactStyles.container}>
                <h1 className={contactStyles.title}>Let’s Chat!</h1>    
                <div className={contactStyles.content}>
                    <div className={contactStyles.item}>
                        <img className={contactStyles.profilePhoto} src={data.contentfulContact.image.file.url} alt={data.contentfulContact.image.file.fileName} />
                    </div>
                    <div className={contactStyles.item}>
                        <div className={contactStyles.form}>
                        <form 
                        acceptCharset="UTF-8"
                        method="POST"
                        enctype="multipart/form-data"
                        id="gatsbyForm"
                        onSubmit={handleSubmit} >
                                <p><label>Name</label></p>
                                <p><input className={contactStyles.input} type="name" name="name" placeholder="Your Name"  value={query.name}
                        onChange={handleChange()}></input></p>
                                <p><label>Email</label></p>
                                <p><input className={contactStyles.input} type="email" name="email" placeholder="Your Email Address" value={query.email}
                        onChange={handleChange()}></input></p>
                                <p><label>Subject</label></p>
                                <p><input className={contactStyles.input} type="subject" name="subject" placeholder="Subject" value={query.subject}
                        onChange={handleChange()}></input></p>
                                <p><label>Message</label></p>
                                <p><textarea className={contactStyles.input} cols="40" rows="10" name="message" id="message" placeholder="Message" value={query.message}
                        onChange={handleChange()}></textarea></p>
                                <p><input className={contactStyles.submit} type="submit" value="send"></input></p>
                                {formStatus ? (
                                    <div className="text-success mb-2">
                                        Your message has been sent.
                                    </div>
                                ) : (
                                    ""
                                )}
                            </form>
                        </div>
                    </div>
                </div>
                <div className={contactStyles.infoCards}>
                    <div className={contactStyles.card}>
                        <h2>Address</h2>
                        <p>{info.address}</p>
                    </div>
                    <div className={contactStyles.card}>
                        <h2>Telephone</h2>
                        <p>{info.telephone}</p>
                    </div>
                    <div className={contactStyles.card}>
                        <h2>Email</h2>
                        <p>{info.email}</p>
                    </div>
                </div>
            </div>
           
        </Layout>
    )
};

export default ContactPage;

